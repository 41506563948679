import { render, staticRenderFns } from "./baiguullaga_ajiliin_bairni_zar_details.vue?vue&type=template&id=7c9923e3&scoped=true&"
import script from "./baiguullaga_ajiliin_bairni_zar_details.vue?vue&type=script&lang=js&"
export * from "./baiguullaga_ajiliin_bairni_zar_details.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c9923e3",
  null
  
)

export default component.exports