<template>
  <div>
    <Button type="primary" class="float-right footer_sanal_btn"
            @click="sendAnket"  v-if="!isSent">{{'anket_ilgeeh' | translate }}
    </Button>
    <Button type="primary" class="float-right footer_sanal_btn" v-else>
        {{'ankit_ilgeesen' | translate }}
    </Button>


    <Modal v-model="anketModal" :title="'ankit' | translate " width="900px;" footer-hide>
      <dataform ref="anketForm" v-if="anketModal" :editMode="anket_id >= 1"  schemaID="2" :onSuccess="onSuccessAnket"
      />
    </Modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
const Dataform = () => import(/* webpackChunkName: "Dataform-el" */'@lambda-platform/dataform/src/Dataform.vue');
import {SEND_AJLIIN_BAIR_ZAR_ANKET_BAIGUULLAGA, SEND_AJLIIN_BAIR_ZAR_ANKET_BRIGAD, GET_ILGEESEN_ANKET_BAIGUULLAGA, GET_ILGEESEN_ANKET_BRIGAD} from "../graphql/anket"
export default {
  props: ["zar_id", "type", "baiguullaga_id", "brid_id"],
  name: "anketIlgeeh",
  components:{
    "dataform": Dataform,
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
     'anket_id'
      // ...
    ])
  },
  data(){
    return {
      anketModal:false,
      isSent:false
    }
  },
  methods:{
    onSuccessAnket(data) {
      this.$store.commit("setAnketID", data.id);
      this.anketModal = false;
      this.send(data.id);
    },
    send(anket_id){
      this.$apollo.mutate({
        mutation: this.type == "baiguullaga" ? SEND_AJLIIN_BAIR_ZAR_ANKET_BAIGUULLAGA : SEND_AJLIIN_BAIR_ZAR_ANKET_BRIGAD,
        variables: this.type == "baiguullaga" ?  {
          anket_id: anket_id,
          zar_id: this.zar_id*1,
          baiguullaga_id:this.baiguullaga_id
        } : {
          anket_id: anket_id,
          zar_id: this.zar_id*1,
          brigad_id:this.brid_id
        },
      }).then(() => {
        this.$Notice.success({
          title: this.$t("successful"),
          desc: this.$t("Your_application_successfully_submitted")
        });
        this.isSent = true;
      })
    },
    sendAnket(){
      if(this.anket_id >= 1){
        this.send(this.anket_id);
      } else {
        this.anketModal = true;
      }
    },
    getData() {
      this.$apollo.query({
        query: this.type == "baiguullaga" ? GET_ILGEESEN_ANKET_BAIGUULLAGA : GET_ILGEESEN_ANKET_BRIGAD,
        variables: {
          anket_id: this.anket_id.toString(),
          zar_id: this.zar_id.toString(),
        },
      }).then(({data}) => {
        if(this.type == "baiguullaga"){
          if(data.irtsen_anket_baiguullaga.length >= 1){
            this.isSent = true;
          } else {
            this.isSent = false;
          }
        } else {
          if(data.irtsen_anket_brigad.length >= 1){
            this.isSent = true;
          } else {
            this.isSent = false;
          }
        }
      })
    },
  },
  mounted() {
    console.log(this.anket_id)
    console.log(this.anket_id)
    console.log(this.anket_id)
    if(this.anket_id >= 1){
      this.getData();
    }
  }
}
</script>
